'use client';

import Head from 'next/head';

export default function Home() {
  return (
    <Head>
      <title>Inkeep Portal</title>
      <meta name="description" content="Inkeep Portal" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
}
